.StrokeMeter {
  grid-area: StrokeMeter;
  position: relative;
}

.StrokeMeter__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0.3;
  border: 1px solid #000;
}

.StrokeMeter__circle--fast {
  transition-duration: 100ms;
}

.StrokeMeter__circle--medium {
  transition-duration: 250ms;
}

.StrokeMeter__circle--slow {
  transition-duration: 550ms;
}

.StrokeMeter__circle--stop {
  transition-duration: 0ms;
}

.StrokeMeter__circle--cumming {
  transition-duration: 0ms !important;
  height: 10vw !important;
  width: 10vw !important;
  background-color: rgb(211, 76, 76) !important;
}

.StrokeMeter__circle--up {
  height: 20vw;
  width: 20vw;
  background: rgb(179, 179, 179);
}

.StrokeMeter__circle--down {
  height: 10vw;
  width: 10vw;
  background: rgb(114, 114, 114);
}

@media screen and (max-width: 700px) {
  .StrokeMeter__circle--up {
    height: 55vw;
    width: 55vw;
    background: rgb(179, 179, 179);
  }

  .StrokeMeter__circle--down {
    height: 30vw;
    width: 30vw;
    background: rgb(114, 114, 114);
  }
}
