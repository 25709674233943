.PornSetting__thumbnails {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.PornSetting__error {
  color: #cc0000;
}

.PornSetting__textarea {
  width: 100%;
  min-height: 6.25em;
}
