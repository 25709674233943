.PornControls__wrapper {
  background: rgba(92, 92, 92, 0.3);
  margin: 10px 0;
  padding: 10px;
  display: inline-flex;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.PornControls__wrapper .settings-button {
  margin: 0 5px;
}

@media screen and (max-width: 700px) {
  .PornControls__wrapper {
    bottom: auto;
    right: auto;
    top: 90px;
    left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .PornControls__wrapper {
    top: 80px;
  }
}
